<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-9">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
            {{ lista_campos != "" ? "Editar" : "Criar" }} Pastas
          </h3>
        </div>
        <div class="card-body">
          <b-form
            @submit.stop.prevent
            @submit="confirm(lista_campos == '' ? 'cria' : 'edita')"
            class="modal-form"
          >
            <b-form-group
              class="form-group-wrapper"
              invalid-feedback="Precisa ter mais de 2 digitos."
              :state="
                formValidat.nome.alreadyChanged
                  ? formValidat.nome.isValid
                  : null
              "
            >
              <b-form-input
                type="text"
                aria-describedby="property-name"
                placeholder="nome"
                required
                v-model="form.nome"
                :state="
                  formValidat.nome.alreadyChanged
                    ? formValidat.nome.isValid
                    : null"
              />
            </b-form-group>
              <b-form-group
              class="form-group-wrapper"
   
          
            >
              <b-form-input
             
                type="text"
                aria-describedby="property-name"
                placeholder="descricao"
             
                v-model="form.descricao"
            
              />
            </b-form-group>

            <!-- <b-form-group class="form-group-wrapper col-md-2">
                <b-form-select
                  class="w-100"
                  :options="personTypeOptions"
                  v-model="form.pastas_id"
                  @input="limpar()"
                />
              </b-form-group> -->
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <b-form-group
                  v-show="!formIsValid()"
                  class="form-group-wrapper"
                  invalid-feedback="Deixar campos prenxidos corretamente."
                  :state="false"
                >
                </b-form-group>
                <button
                  type="submit"
                  size="sm"
                  class="btn btn-primary"
                  :disabled="!formIsValid()"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: "",
        descricao:'',
        subpastas_id: 0,
      },
      formValidat: {
        nome: {
          isValid: false,
          alreadyChanged: false,
        },
       
      },

      verif: false,
    };
  },
  created() {
    this.listar_requerimentos();
    this.preenxerCampos();
  },
  watch: {
    form: {
      handler() {
        this.formIsValid();
      },
      deep: true,
    },
    "form.nome"() {
      this.formValidat.nome.alreadyChanged = true;
      this.formValidat.nome.isValid = this.form.nome.length > 2;
    },
 

    // "form.pastas_id"() {
    //   this.formValidat.numero.alreadyChanged = true;
    //   this.formValidat.numero.isValid = Number(this.form.numero) > 0;
    // },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pastas" }]);
  },
  computed: {
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },

    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.pastas.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` uma Pasta no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {

      if(this.$route.params.pastas_id){
        this.form.subpastas_id=this.$route.params.pastas_id
      }
// console.log(this.form)
      this.verif = true;
      await this.$store.dispatch("pastas/create_pastas", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "arquivex",
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("pastas/update_pastas", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "arquivex",
      });
    },
    formIsValid() {
      return Object.values(this.formValidat).every((field) => !!field.isValid);
    },

    listar_requerimentos() {
      this.$store.dispatch("configEmpresa/listar_filial");
    },
    preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          sequecia: this.lista_campos.sequecia,
          descricao: this.lista_campos.numero,
          subpastas_id: this.lista_campos.subpastas_id,
    

          user_in: this.lista_campos.user_in,
          user_up: this.lista_campos.user_up,
          filial_id: this.lista_campos.filial_id,
        };
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>